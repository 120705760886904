import { Injectable } from '@angular/core'
import { Nullable } from '@sportiananalysis/ui/lib/models/type-helpers.models';

@Injectable({
  providedIn: 'root',
})

@Injectable()
export class ZIndexManagerService {
  private _zIndexes: { key: string; value: number }[] = []

  getZIndex(element: HTMLElement) {
    return element ? parseInt(element.style.zIndex, 10) || 0 : 0
  }

  setZIndex(element: Nullable<HTMLElement>, key: string, baseZIndex: number): void {
    if (element) {
      element.style.zIndex = String(this._generateIndex(key, baseZIndex))
    }
  }

  clearZIndex(element: HTMLElement): void {
    if (element) {
      this._revertZIndex(this.getZIndex(element))
      element.style.zIndex = ''
    }
  }

  getCurrentZIndex() {
    return this._zIndexes.length > 0 ? this._zIndexes[this._zIndexes.length - 1].value : 0
  }

  private _generateIndex(key: string, baseZIndex: number) {
    let lastZIndex = this._zIndexes.length > 0 ? this._zIndexes[this._zIndexes.length - 1] : { key, value: baseZIndex }
    let newZIndex = lastZIndex.value + (lastZIndex.key === key ? 0 : baseZIndex) + 1

    this._zIndexes.push({ key, value: newZIndex })

    return newZIndex
  }

  private _revertZIndex(zIndex: number) {
    this._zIndexes = this._zIndexes.filter((obj) => obj.value !== zIndex)
  }
}
