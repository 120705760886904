import { dashboardReducer, dashboardTypesReducer, datasetSelectorReducer, eventSummaryReducer, matchEventsReducer } from './dashboard';
import { playlistReducer } from './playlist';
import { translationReducer } from './translation';

export const reducers = {
  dashboard: dashboardReducer,
  dashboardTypes: dashboardTypesReducer,
  translation: translationReducer,
  matchEvents: matchEventsReducer,
  eventSummary: eventSummaryReducer,
  datasetSelectors: datasetSelectorReducer,
  playlist: playlistReducer,
  // Add more reducers here
};