import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ToastComponent } from './components/toast/toast.component'
import { ToastService } from './services/toast.service'
import { OverlayModule } from '@angular/cdk/overlay'
import { ToastIconComponent } from './components/toast-icon/toast-icon.component'

@NgModule({
  declarations: [ToastComponent, ToastIconComponent],
  imports: [CommonModule, OverlayModule],
  providers: [ToastService]
})
export class ToastModule {}
