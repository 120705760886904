import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './components/dialog/dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DialogContentDirective } from './directives/dialog-content.directive';
import { ZIndexManagerService } from '../../services/z-index-manager/z-index-manager.service';
import { TemplateDirective } from '../../shared/directives/template/template.directive';

@NgModule({
  declarations: [
    DialogComponent,
    HeaderComponent,
    FooterComponent,
    DialogContentDirective,
    TemplateDirective,
  ],
  exports: [
    DialogComponent,
    HeaderComponent,
    FooterComponent,
    TemplateDirective,
  ],
  imports: [CommonModule],
  providers: [ZIndexManagerService],
})
export class DialogModule {}
