import { APIParams } from "../core/types/spa.types";

export const SUPPORTED_LANGUAGES = [
  { key: "en", name: "English", altName: "en-GB" },
  { key: "es", name: "Español", altName: "es-ES" },
];

export const DASHBOARD_API_PARAM: APIParams = {
  sortBy: "modificationDate",
  sortOrder: "desc",
  page: 1,
  pageSize: 10,
};

interface Configuration {
  Rows: number;
  Columns: number;
}

interface ZoneConfiguration {
  rows: number;
  columns: number;
}

export const ZoneConfiguration: { [key: string]: Configuration } = {
  "6x5": {
    Rows: 5,
    Columns: 6,
  },
  "4x4": {
    Rows: 4,
    Columns: 4,
  },
  "15x18": {
    Rows: 15,
    Columns: 18,
  },
};

export const ZoneConfigurationConversion: { [key: string]: ZoneConfiguration } = {
  "6x5": {
    rows: 5,
    columns: 6,
  },
  "12x10": {
    rows: 10,
    columns: 12,
  },
  "18x15": {
    rows: 15,
    columns: 18,
  },
};

export const PLAYER_POSITIONS_EN: string[] = ['goalkeeper', 'defender', 'midfielder', 'offensive midfielder', 'forward', 'fordward'];
export const PLAYER_POSITIONS_ES: string[] = ['portero', 'defensa', 'centrocampista', 'centrocampista de ataque', 'delantero'];

export const VideoConfiguration = {
  delayTime: 5, // default time to be subtracted from the video timestamp
};

export const MAX_SUMMARY_EVENT_TYPES = Infinity;
