export const Z_INDEX_CONFIG: any = {
  modal: 1000,
  overlay: 999,
};

export const DIALOG_TEMPLATE_MAP: Map<string, string> = new Map<string, string>(
  [
    ['header', 'headerTemplate'],
    ['content', 'contentTemplate'],
    ['footer', 'footerTemplate'],
    ['closeicon', 'headerTemplate'],
    ['header', 'headerTemplate'],
  ]
);
