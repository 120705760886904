import { PLAYER_POSITIONS_EN, PLAYER_POSITIONS_ES } from 'src/app/constants/app.constants';
import { Player } from 'src/app/core/types/dashboard-selector.types';

/**
 * Retrieves selected players from the current list based on changes from the previous list.
 * @param previousPlayers Old array of players from the previous API call.
 * @param currentPlayers New array of players from the current API call.
 * @param previousSelectedPlayers The array of selected players.
 * @returns An array of selected players.
 */
export const getSelectedPlayers = (
  previousPlayers: Player[],
  currentPlayers: Player[],
  previousSelectedPlayers: Player[]
): Player[] => {
  if (!currentPlayers || currentPlayers.length == 0) {
    return [];
  }

  if (
    (previousPlayers && previousPlayers.length == 0) ||
    (previousSelectedPlayers && previousSelectedPlayers.length == 0)
  ) {
    return currentPlayers;
  }

  // Filter previous players based on selected team IDs
  const filteredPrevPlayers = getCurrentTeamPlayers(
    previousPlayers,
    currentPlayers
  );
  const filteredSelectedPlayers = getCurrentTeamPlayers(
    previousSelectedPlayers,
    currentPlayers
  );

  // Get added players that are in currentPlayers but not in previousPlayers
  const addedPlayers = getAddedPlayers(filteredPrevPlayers, currentPlayers);

  const selectedPlayers = [...filteredSelectedPlayers, ...addedPlayers];

  return selectedPlayers;
};

/**
 * Returns the players that are not present in the previous team but are present in the current team.
 * @param previousPlayers An array of players from the previous team.
 * @param currentPlayers An array of players from the current team.
 * @returns An array of players that have been added to the current team.
 */
export const getAddedPlayers = (
  previousPlayers: Player[],
  currentPlayers: Player[]
): Player[] => {
  const idSet = new Set(previousPlayers.map((player) => player.id));

  const addedPlayers = currentPlayers.filter((player) => !idSet.has(player.id));

  return addedPlayers;
};

/**
 * Removes players from the previous selected team that do not exist in the current team.
 * @param previousPlayers An array of players from the previous team.
 * @param currentPlayers An array of players from the current team.
 * @returns An array of players that belong to the current team.
 */
export const getCurrentTeamPlayers = (
  previousPlayers: Player[],
  currentPlayers: Player[]
): Player[] => {
  const idSet = new Set(currentPlayers.map((player) => player.id));

  const players = previousPlayers.filter((player) => idSet.has(player.id));
  return players;
};

/**
 * Returns the players in custom sorted order.
 * @param players An array of players.
 * @returns An array of players sorted in custom order.
 */

export const sortPlayers = (
  players: Player[], currentLanguage: string
): Player[] => {
  let positionOrder: string | any[] = [];
  // TODO: chnage the positionOrder list to handle all language
  if (currentLanguage === 'en') {
    positionOrder = PLAYER_POSITIONS_EN;
  } else {
    positionOrder = PLAYER_POSITIONS_ES;
  }
  players.sort((a: any, b: any) => {
    // Handle null or undefined positionDescriptions by pushing them to the end
    if (!a.positionDescription && b.positionDescription) return 1;  // `a` is null, push down
    if (a.positionDescription && !b.positionDescription) return -1; // `b` is null, push down
    if (!a.positionDescription && !b.positionDescription) return 0;  // Both are null

    // Normalize positionDescription values (trim spaces and lowercase)
    const positionA = positionOrder.indexOf(a.positionDescription?.trim().toLowerCase());
    const positionB = positionOrder.indexOf(b.positionDescription?.trim().toLowerCase());

    // Step 1: Sort by position in the custom order
    // If position is not found in the order list, push it to the end
    if (positionA === -1 && positionB === -1) return 0;  // Both positions not found
    if (positionA === -1) return 1; // `a` has a non-specified position, push to the end
    if (positionB === -1) return -1; // `b` has a non-specified position, push to the end

    if (positionA !== positionB) {
      return positionA - positionB; // Sort by custom position order
    }

    // Step 2: If positions are the same, sort by shirt number (ascending)
    // Handle null/undefined shirt numbers (push nulls to the end)
    if (a.number != null && b.number != null) {
      if (a.number !== b.number) {
        return a.number - b.number;
      }
    } else if (a.number == null && b.number != null) {
      return 1; // `a` number is null, push it to the end
    } else if (a.number != null && b.number == null) {
      return -1; // `b` number is null, push it to the end
    }

    // Step 3: If positions and shirt numbers are the same, sort by shirt name (ascending)
    // Handle null/undefined shirt names (push nulls to the end)
    if (a.name && b.name) {
      return a.name.localeCompare(b.name);
    } else if (!a.name && b.name) {
      return 1; // `a` name is null, push to the end
    } else if (a.name && !b.name) {
      return -1; // `b` name is null, push to the end
    }
  });

  return players;
};
