import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[spauiTemplate]',
})
export class TemplateDirective {
  @Input() templateType: string | undefined;

  @Input('spauiTemplate') name: string | undefined;

  constructor(public template: TemplateRef<any>) {}

  getTemplateType(): string {
    return this.name!;
  }
}
