<div
  *ngIf="maskVisible"
  class="{{ [maskStyleClass, 'spaui-dialog-mask'].join(' ') }}"
  [ngClass]="{
    'spaui-dialog-inline': this.inlineWrapperSelector,
    'spaui-component-overlay spaui-component-overlay-enter': this.modal,
    'spaui-dialog-mask-scrollblocker': this.modal || this.blockScroll,
    'spaui-dialog-position-left': position === 'left',
    'spaui-dialog-position-right': position === 'right',
    'spaui-dialog-position-top': position === 'top',
    'spaui-dialog-position-top-left': position === 'topleft',
    'spaui-dialog-position-top-right': position === 'topright',
    'spaui-dialog-position-bottom': position === 'bottom',
    'spaui-dialog-position-bottom-left': position === 'bottomleft',
    'spaui-dialog-position-bottom-right': position === 'bottomright'
  }"
>
  <div
    *ngIf="visible"
    spauiFocusTrap
    class="{{ [styleClass, 'spaui-dialog'].join(' ') }}"
    [ngClass]="{
      'spaui-dialog-rtl': rtl,
      'spaui-dialog-draggable': draggable,
      'spaui-dialog-maximized': maximized
    }"
    [ngStyle]="style"
    [@animation]="{
      value: 'visible',
      params: { transform: transformOptions, transition: transitionOptions }
    }"
    (@animation.start)="onAnimationStart($event)"
    (@animation.done)="onAnimationEnd($event)"
    role="dialog"
    [style.width]="width"
    [style.height]="height"
    [attr.aria-labelledby]="id + '-label'"
  >
    <div
      #titlebar
      class="spaui-dialog-header"
      (mousedown)="initDrag($event)"
      *ngIf="showHeader"
    >
      <div class="spaui-dialog-title-wrapper">
        <img
          *ngIf="headerIcon"
          [src]="headerIcon"
          class="spaui-dialog-title-icon"
          alt="title"
        />
        <p
          [attr.id]="id + '-label'"
          class="spaui-dialog-title"
          *ngIf="!headerFacet && !headerTemplate"
          >{{ header }}</p
        >
        <p
          [attr.id]="id + '-label'"
          class="spaui-dialog-title"
          *ngIf="headerFacet"
        >
          <ng-content select="spaui-header"></ng-content>
        </p>
      </div>
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <div class="spaui-dialog-header-icons">
        <button
          *ngIf="maximizable"
          type="button"
          class="spaui-dialog-header-icon spaui-dialog-header-maximize spaui-link"
          [class.minimizable]="maximized"
          [class.maximizable]="!maximized"
          (click)="maximize()"
          (keydown.enter)="maximize()"
          tabindex="-1"
          spauiRipple
        >
          <span
            *ngIf="
              maximizeIcon && !maximizeIconTemplate && !minimizeIconTemplate
            "
            class="spaui-dialog-header-maximize-icon"
            [ngClass]="maximized ? minimizeIcon : maximizeIcon"
          ></span>
          <!-- TODO: Implement minimize and maximize icon -->
          <!-- <spaui-icon
            svgIcon="maximize"
            *ngIf="!maximized && !maximizeIconTemplate && !maximizeIcon"
            class="spaui-dialog-header-maximize-icon maximize"></spaui-icon>
          <spaui-icon
            svgIcon="minimize"
            *ngIf="maximized && !minimizeIconTemplate && !minimizeIcon"
            class="spaui-dialog-header-maximize-icon minimize"></spaui-icon> -->
          <ng-container *ngIf="!maximized">
            <ng-template *ngTemplateOutlet="maximizeIconTemplate"></ng-template>
          </ng-container>
          <ng-container *ngIf="maximized">
            <ng-template *ngTemplateOutlet="minimizeIconTemplate"></ng-template>
          </ng-container>
        </button>
        <button
          *ngIf="closable"
          type="button"
          class="spaui-dialog-header-icon spaui-dialog-header-close spaui-link"
          [attr.aria-label]="closeAriaLabel"
          (click)="close($event); closeDialogRef()"
          (keydown.enter)="close($event); closeDialogRef()"
          [attr.tabindex]="closeTabindex"
          spauiRipple
        >
          <ng-container *ngIf="!closeIconTemplate">
            <!-- TODO: Replace image with icon -->
            <img
              src="assets/images/icons/close.svg"
              *ngIf="!closeIcon"
              alt="close"
            />
          </ng-container>
          <span *ngIf="closeIconTemplate">
            <ng-template *ngTemplateOutlet="closeIconTemplate"></ng-template>
          </span>
        </button>
      </div>
    </div>
    <div
      #content
      [ngClass]="'spaui-dialog-content'"
      [ngStyle]="contentStyle"
      [class]="contentStyleClass"
    >
      <ng-content></ng-content>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      <ng-template spauiDialogContent></ng-template>
    </div>
    <div
      #footer
      class="spaui-dialog-footer"
      *ngIf="footerFacet || footerTemplate"
    >
      <ng-content select="spaui-footer"></ng-content>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  </div>
</div>
