export function runOutsideAngular(
  target: object,
  methodName: string,
  propertyDescriptor: PropertyDescriptor
) {
  const originalFunction = propertyDescriptor.value;
  propertyDescriptor.value = function (...args: any[]) {
    if ((this as any)._zone) {
      (this as any)._zone.runOutsideAngular(() => {
        originalFunction.call(this, ...args);
      });
    } else {
      console.warn(
        'No ngZone injected in the main class, running method as usual. Inject this service as `ngZone` in order to get it working'
      );
      originalFunction.call(this, ...args);
    }
  };
  return propertyDescriptor;
}
