import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  SkipSelf,
  TemplateRef,
  Type,
  ViewChild,
  ViewEncapsulation,
  ViewRef,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import {
  AnimationEvent,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { Z_INDEX_CONFIG } from '../../constants/dialog.constants';
import {
  hideDialogAnimation,
  showDialogAnimation,
} from '../../animations/dialog.animations';
import {
  DialogConfig,
  DialogConfigKey,
} from '../../models/dialog-config.model';
import { DialogContentDirective } from '../../directives/dialog-content.directive';
import { DialogRef } from '../../models/dialog-ref.model';
import { DialogPosition } from '../../models/dialog.models';
import { TemplateDirective } from '../../../../shared/directives/template/template.directive';
import { ZIndexManagerService } from '../../../../services/z-index-manager/z-index-manager.service';
import { runOutsideAngular } from '../../decorators/run-outside-angular.decorator';
import {
  buildUniqueComponentId,
  getFocusableElements,
  appendChild,
  getElementOuterDimension,
  getViewport,
} from 'src/app/shared/utils';
import { Nullable, VoidListener } from '../../models/type-helpers.models';

@Component({
  selector: 'spaui-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('animation', [
      transition('void => visible', [useAnimation(showDialogAnimation)]),
      transition('visible => void', [useAnimation(hideDialogAnimation)]),
    ]),
  ],
})
export class DialogComponent implements AfterContentInit, OnInit, OnDestroy {
  /**
   * Title text of the dialog.
   */
  @Input() header: Nullable<string>;

  /**
   * Title icon of the dialog.
   */
  @Input() headerIcon: Nullable<string>;


  /**
   * Enables dragging to change the position using header.
   */
  @Input() draggable: boolean = false;

  /**
   * Style of the content section.
   */
  @Input() contentStyle!: Partial<CSSStyleDeclaration>;
  /**
   * Style class of the content.
   */
  @Input() contentStyleClass!: string;
  /**
   * Defines if background should be blocked when the dialog is displayed.
   */
  @Input() modal: boolean = true;
  /**
   * Specifies if pressing the escape key should hide the dialog.
   */
  @Input() closeOnEscape: boolean = true;
  /**
   * Specifies if clicking the modal background should hide the dialog.
   */
  @Input() dismissableMask: boolean = false;
  /**
   * When enabled dialog is displayed in RTL direction.
   */
  @Input() rtl: boolean = false;
  /**
   * Adds a close icon to the header to hide the dialog.
   */
  @Input() closable: boolean = true;

  /**
   * Target element to attach the dialog, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   */
  @Input() appendTo:
    | HTMLElement
    | ElementRef
    | TemplateRef<any>
    | string
    | null
    | undefined
    | any;
  /**
   * Object literal to define widths per screen size.
   */
  @Input() breakpoints: any;
  /**
   * Style class of the component.
   */
  @Input() styleClass: Nullable<string>;
  /**
   * Style class of the mask.
   */
  @Input() maskStyleClass: Nullable<string>;
  /**
   * Whether to show the header or not.
   */
  @Input() showHeader: boolean = true;

  /**
   * Whether background scroll should be blocked when the dialog is visible.
   */
  @Input() blockScroll: boolean = false;
  /**
   * Whether to automatically manage layering.
   */
  @Input() autoZIndex: boolean = true;
  /**
   * Base zIndex value to use in layering.
   */
  @Input() baseZIndex: number = 0;
  /**
   * Minimum value for the left coordinate of dialog in dragging.
   */
  @Input() minX: number = 0;
  /**
   * Minimum value for the top coordinate of dialog in dragging.
   */
  @Input() minY: number = 0;
  /**
   * When enabled, the first button receives focus on show.
   */
  @Input() focusOnShow: boolean = true;
  /**
   * Whether the dialog can be displayed full screen.
   */
  @Input() maximizable: boolean = false;
  /**
   * Keeps dialog in the viewport.
   */
  @Input() keepInViewport: boolean = true;
  /**
   * When enabled, can only focus on elements inside the dialog.
   */
  @Input() focusTrap: boolean = true;
  /**
   * Transition options of the animation.
   */
  @Input() transitionOptions: string = '150ms cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Name of the close icon.
   */
  @Input() closeIcon: Nullable<string>;
  /**
   * Defines a string that labels the close button for accessibility.
   */
  @Input() closeAriaLabel: Nullable<string>;
  /**
   * Index of the close button in tabbing order.
   */
  @Input() closeTabindex: string = '-1';
  /**
   * Name of the minimize icon.
   */
  @Input() minimizeIcon: Nullable<string>;
  /**
   * Name of the maximize icon.
   */
  @Input() maximizeIcon: Nullable<string>;

  /**
   * When it's defined, the dialog opens up relative to the element and displays a modal backdrop layer behind
   */
  @Input() inlineWrapperSelector: Nullable<string>;

  /**
   * Width of the dialog.
   */
  @Input()
  width?: string;

  /**
   * Height of the dialog.
   */
  @Input() height?: string;

  /**
   * Specifies the visibility of the dialog.
   */
  @Input() get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;

    if (this._visible && !this.maskVisible) {
      this.maskVisible = true;
    }
  }

  /**
   * Inline style of the component.
   */
  @Input() get style(): Partial<CSSStyleDeclaration> {
    return this._style;
  }

  set style(value: Partial<CSSStyleDeclaration>) {
    if (value) {
      this._style = { ...value };
      this.originalStyle = value;
    }
  }

  /**
   * Position of the dialog.
   */
  @Input() get position(): DialogPosition {
    return this._position;
  }

  set position(value: DialogPosition) {
    this._position = value;

    switch (value) {
      case 'topleft':
      case 'bottomleft':
      case 'left':
        this.transformOptions = 'translate3d(-100%, 0px, 0px)';
        break;
      case 'topright':
      case 'bottomright':
      case 'right':
        this.transformOptions = 'translate3d(100%, 0px, 0px)';
        break;
      case 'bottom':
        this.transformOptions = 'translate3d(0px, 100%, 0px)';
        break;
      case 'top':
        this.transformOptions = 'translate3d(0px, -100%, 0px)';
        break;
      default:
        this.transformOptions = 'scale(0.7)';
        break;
    }
  }

  /**
   * Callback to invoke when dialog is shown.
   * @group Emits
   */
  @Output() showChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Callback to invoke when dialog is hidden.
   * @group Emits
   */
  @Output() hideChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * This EventEmitter is used to notify changes in the visibility state of a component.
   * @param {boolean} value - New value.
   * @group Emits
   */
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Callback to invoke when dialog resizing is initiated.
   * @param {MouseEvent} event - Mouse event.
   * @group Emits
   */
  @Output() resizeInitChange: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  /**
   * Callback to invoke when dialog resizing is completed.
   * @param {MouseEvent} event - Mouse event.
   * @group Emits
   */
  @Output() resizeEndChange: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  /**
   * Callback to invoke when dialog dragging is completed.
   * @param {DragEvent} event - Drag event.
   * @group Emits
   */
  @Output() dragEndChange: EventEmitter<DragEvent> =
    new EventEmitter<DragEvent>();
  /**
   * Callback to invoke when dialog maximized or unmaximized.
   * @group Emits
   */
  @Output() maximizeChange: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild(HeaderComponent) headerFacet: Nullable<
    QueryList<HeaderComponent>
  >;
  @ContentChild(FooterComponent) footerFacet: Nullable<
    QueryList<FooterComponent>
  >;
  @ContentChildren(TemplateDirective) private _templates: Nullable<
    QueryList<TemplateDirective>
  >;

  @ViewChild(DialogContentDirective)
  set insertionPoint(_insertPoint: Nullable<DialogContentDirective>) {
    this._insertionPoint = _insertPoint;
    if (_insertPoint && this.contentComponentType) {
      this.loadChildComponent(_insertPoint);
      this._cdr.detectChanges();
    }
  }

  get insertPoint() {
    return this._insertionPoint;
  }

  @ViewChild('titlebar') headerViewChild: Nullable<ElementRef>;
  @ViewChild('content') contentViewChild: Nullable<ElementRef>;
  @ViewChild('footer') footerViewChild: Nullable<ElementRef>;

  private readonly _window: Window;
  private _visible: boolean = false;
  private _style: any = {};
  private _position: DialogPosition = 'center';
  private _insertionPoint: Nullable<DialogContentDirective>;

  componentRef: Nullable<ComponentRef<any>>;
  contentComponentType: Nullable<Type<any>>;
  headerTemplate!: TemplateRef<any>;
  contentTemplate!: TemplateRef<any>;
  footerTemplate!: TemplateRef<any>;
  maximizeIconTemplate!: TemplateRef<any>;
  closeIconTemplate!: TemplateRef<any>;
  minimizeIconTemplate!: TemplateRef<any>;
  maskVisible: Nullable<boolean>;
  container: Nullable<HTMLDivElement>;
  wrapper: Nullable<HTMLElement>;
  dragging: Nullable<boolean>;
  documentDragListener: VoidListener;
  documentDragEndListener: VoidListener;
  documentKeydownListener: VoidListener;
  documentEscapeListener: VoidListener;
  maskClickListener: VoidListener;
  lastPageX: Nullable<number>;
  lastPageY: Nullable<number>;
  preventVisibleChangePropagation: Nullable<boolean>;
  maximized: Nullable<boolean>;
  preMaximizeContentHeight: Nullable<number>;
  preMaximizeContainerWidth: Nullable<number>;
  preMaximizeContainerHeight: Nullable<number>;
  preMaximizePageX: Nullable<number>;
  preMaximizePageY: Nullable<number>;
  originalStyle!: Partial<CSSStyleDeclaration>;
  transformOptions: string = 'scale(0.7)';
  styleElement: any;
  id: string = buildUniqueComponentId();

  get parent(): Element | undefined {
    const domElements = Array.from(
      this._document.getElementsByClassName('spaui-dialog')
    );
    if (domElements.length > 1) {
      return domElements.pop();
    }
    return undefined;
  }

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(PLATFORM_ID) private readonly _platformId: any,
    private readonly _el: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _zone: NgZone,
    private readonly _zIndexManager: ZIndexManagerService,
    @Optional() private readonly config: DialogConfig,
    @Optional() private readonly _dialogRef: DialogRef,
    @SkipSelf() @Optional() private parentDialog: DialogComponent
  ) {
    this._window = this._document.defaultView as Window;
    this._setupComponent(config);
  }

  ngAfterContentInit() {
    this._templates?.forEach((item) => {
      const tplName = `${item.getTemplateType()}Template`;
      (this as any)[tplName] = item.template;
    });
  }

  ngOnInit() {
    if (this.breakpoints) {
      this.createStyle();
    }
  }

  ngOnDestroy() {
    if (this.container) {
      this.restoreAppend();
      this.onContainerDestroy();
    }

    this.destroyStyle();
    this.componentRef?.destroy();
  }

  loadChildComponent(insertPoint: DialogContentDirective) {
    let viewContainerRef = insertPoint?.viewContainerRef;
    viewContainerRef?.clear();

    this.componentRef = viewContainerRef?.createComponent(
      this.contentComponentType!
    );
  }

  close(event?: Event) {
    event?.stopPropagation();
    event?.preventDefault();
    this.visibleChange.emit(false);
    if (this._dialogRef) {
      this.visible = false;
      this._cdr.markForCheck();
    }
  }

  @runOutsideAngular
  focus() {
    // TODO: Watch out ngZone here
    let focusable = getFocusableElements(this.container as HTMLElement);
    if (focusable) {
      setTimeout(() => focusable[0]?.focus(), 5);
    }
  }

  maximize() {
    this.maximized = !this.maximized;

    if (!this.modal && !this.blockScroll) {
      if (this.maximized) {
        this._addStyleClass(this._document.body, 'spaui-overflow-hidden');
      } else {
        this._removeStyleClass(this._document.body, 'spaui-overflow-hidden');
      }
    }

    this.maximizeChange.emit({ maximized: this.maximized });
    this._dialogRef?.maximize({ maximized: this.maximized });
  }

  moveOnTop() {
    if (this.autoZIndex) {
      this._zIndexManager.setZIndex(
        this.container,
        'modal',
        this.baseZIndex + Z_INDEX_CONFIG.modal
      );
      (this.wrapper as HTMLElement).style.zIndex = String(
        parseInt((this.container as HTMLDivElement).style.zIndex, 10) - 1
      );
    }
  }

  resetPosition() {
    (this.container as HTMLDivElement).style.position = '';
    (this.container as HTMLDivElement).style.left = '';
    (this.container as HTMLDivElement).style.top = '';
    (this.container as HTMLDivElement).style.margin = '';
  }

  createStyle() {
    if (isPlatformBrowser(this._platformId) && !this.styleElement) {
      this.styleElement = this._renderer.createElement('style');
      this.styleElement.type = 'text/css';
      this._renderer.appendChild(this._document.head, this.styleElement);
      let innerHTML = '';
      for (let breakpoint in this.breakpoints) {
        innerHTML += `
                        @media screen and (max-width: ${breakpoint}) {
                            .spaui-dialog[${this.id}] {
                                width: ${this.breakpoints[breakpoint]} !important;
                            }
                        }
                    `;
      }

      this._renderer.setProperty(this.styleElement, 'innerHTML', innerHTML);
    }
  }

  destroyStyle() {
    if (this.styleElement) {
      this._renderer.removeChild(this._document.head, this.styleElement);
      this.styleElement = null;
    }
  }

  appendContainer() {
    if (this.appendTo) {
      if (this.appendTo === 'body' && !this.inlineWrapperSelector) {
        this._renderer.appendChild(this._document.body, this.wrapper);
      } else {
        appendChild(this.wrapper, this.appendTo);
      }
    }
  }

  restoreAppend() {
    if (this.container && this.appendTo) {
      this._renderer.appendChild(this._el.nativeElement, this.wrapper);
    }
  }

  enableModality() {
    if (this.closable && this.dismissableMask) {
      this.maskClickListener = this._renderer.listen(
        this.wrapper,
        'mousedown',
        (event: any) => {
          if (this.wrapper && this.wrapper.isSameNode(event.target)) {
            this.close(event);
          }
        }
      );
    }

    if (this.modal) {
      this._addStyleClass(this._document.body, 'spaui-overflow-hidden');
    }
  }

  disableModality() {
    if (this.wrapper) {
      if (this.dismissableMask) {
        this.unbindMaskClickListener();
      }

      if (this.modal) {
        this._removeStyleClass(this._document.body, 'spaui-overflow-hidden');
      }

      if (!(this._cdr as ViewRef).destroyed) {
        this._cdr.detectChanges();
      }
    }
  }

  initDrag(event: MouseEvent) {
    if (
      this._hasClass(event.target as HTMLElement, 'spaui-dialog-header-icon') ||
      this._hasClass(
        (<HTMLElement>event.target).parentElement,
        'spaui-dialog-header-icon'
      )
    ) {
      return;
    }

    if (this.draggable) {
      this.dragging = true;
      this.lastPageX = event.pageX;
      this.lastPageY = event.pageY;
      (this.container as HTMLDivElement).style.margin = '0';
      this._addStyleClass(this._document.body, 'spaui-unselectable-text');
      this._dialogRef?.dragStart(event);
    }
  }

  onDrag(event: MouseEvent) {
    if (this.dragging) {
      let containerWidth = getElementOuterDimension(
        this.container as HTMLElement,
        'width'
      );
      let containerHeight = getElementOuterDimension(
        this.container as HTMLElement,
        'height'
      );
      let deltaX = event.pageX - (this.lastPageX as number);
      let deltaY = event.pageY - (this.lastPageY as number);
      let offset = (this.container as HTMLDivElement).getBoundingClientRect();
      let leftPos = offset.left + deltaX;
      let topPos = offset.top + deltaY;
      let viewport = getViewport();

      (this.container as HTMLDivElement).style.position = 'fixed';

      if (this.keepInViewport) {
        if (leftPos >= this.minX && leftPos + containerWidth < viewport.width) {
          this._style.left = leftPos + 'px';
          this.lastPageX = event.pageX;
          (this.container as HTMLDivElement).style.left = leftPos + 'px';
        }

        if (topPos >= this.minY && topPos + containerHeight < viewport.height) {
          this._style.top = topPos + 'px';
          this.lastPageY = event.pageY;
          (this.container as HTMLDivElement).style.top = topPos + 'px';
        }
      } else {
        this.lastPageX = event.pageX;
        (this.container as HTMLDivElement).style.left = leftPos + 'px';
        this.lastPageY = event.pageY;
        (this.container as HTMLDivElement).style.top = topPos + 'px';
      }
    }
  }

  endDrag(event: DragEvent) {
    if (this.dragging) {
      this.dragging = false;
      this._removeStyleClass(this._document.body, 'p-unselectable-text');
      this.dragEndChange.emit(event);
      this._dialogRef?.dragEnd(event);
      this._cdr.detectChanges();
    }
  }

  onKeydown(event: KeyboardEvent) {
    if (this.focusTrap && event.which === 9) {
      event.preventDefault();
      this.trapFocus(event.shiftKey);
    }
  }
  
  private trapFocus(isShiftPressed: boolean) {
    const focusableElements = this.getFocusableElements();
  
    if (!focusableElements.length) return;
  
    const activeElement = document.activeElement as HTMLElement;
    const focusedIndex = focusableElements.indexOf(activeElement);
  
    if (isShiftPressed) {
      this.focusPreviousElement(focusableElements, focusedIndex);
    } else {
      this.focusNextElement(focusableElements, focusedIndex);
    }
  }
  
  private getFocusableElements(): HTMLElement[] {
    // Assuming getFocusableElements is defined elsewhere and returns an array of focusable elements
    return getFocusableElements(this.container as HTMLDivElement);
  }
  
  private focusPreviousElement(focusableElements: HTMLElement[], focusedIndex: number) {
    if (focusedIndex <= 0) {
      focusableElements[focusableElements.length - 1].focus();
    } else {
      focusableElements[focusedIndex - 1].focus();
    }
  }
  
  private focusNextElement(focusableElements: HTMLElement[], focusedIndex: number) {
    if (focusedIndex === -1 || focusedIndex === focusableElements.length - 1) {
      focusableElements[0].focus();
    } else {
      focusableElements[focusedIndex + 1].focus();
    }
  }

  bindGlobalListeners() {
    this.parentDialog?.unbindDocumentKeydownListener();
    this.bindDocumentKeydownListener();

    if (this.draggable) {
      this.bindDocumentDragListener();
      this.bindDocumentDragEndListener();
    }

    if (this.closeOnEscape && this.closable) {
      this.bindDocumentEscapeListener();
    }
  }

  unbindGlobalListeners() {
    this.unbindDocumentKeydownListener();
    this.unbindDocumentEscapeListener();
    this.unbindDocumentDragListener();
    this.unbindDocumentDragEndListener();
    this.parentDialog?.bindDocumentKeydownListener();
  }

  @runOutsideAngular
  bindDocumentKeydownListener() {
    if (isPlatformBrowser(this._platformId) && !this.documentKeydownListener) {
      this.documentKeydownListener = this._renderer.listen(
        this._document,
        'keydown',
        this.onKeydown.bind(this)
      );
    }
  }

  unbindDocumentKeydownListener() {
    if (this.documentKeydownListener) {
      this.documentKeydownListener();
      this.documentKeydownListener = null;
    }
  }

  @runOutsideAngular
  bindDocumentDragListener() {
    if (isPlatformBrowser(this._platformId) && !this.documentDragListener) {
      //TODO: Watch out ngZone here
      this.documentDragListener = this._renderer.listen(
        this._window,
        'mousemove',
        this.onDrag.bind(this)
      );
    }
  }

  unbindDocumentDragListener() {
    if (this.documentDragListener) {
      this.documentDragListener();
      this.documentDragListener = null;
    }
  }

  @runOutsideAngular
  bindDocumentDragEndListener() {
    //TODO: Watch out ngZone here
    if (isPlatformBrowser(this._platformId) && !this.documentDragEndListener) {
      this.documentDragEndListener = this._renderer.listen(
        this._window,
        'mouseup',
        this.endDrag.bind(this)
      );
    }
  }

  unbindDocumentDragEndListener() {
    if (this.documentDragEndListener) {
      this.documentDragEndListener();
      this.documentDragEndListener = null;
    }
  }

  bindDocumentEscapeListener() {
    const documentTarget: any = this._el
      ? this._el.nativeElement.ownerDocument
      : 'document';

    this.documentEscapeListener = this._renderer.listen(
      documentTarget,
      'keydown',
      (event) => {
        if (event.which == 27) {
          this.close(event);
          this.closeDialogRef();
          // TODO: Careful, in the original implementation of dynamic dialog is enabled.
          //  Disabled for now
          //  https://github.com/primefaces/primeng/blob/cea0b3670e51497ba8c936abadffb017e4f87579/src/app/components/dynamicdialog/dynamicdialog.ts#L595
          // if (parseInt((this.container as HTMLDivElement).style.zIndex) == this._zIndexManager.getCurrentZIndex()) {
          //   this.hide()
          // }
        }
      }
    );
  }

  unbindDocumentEscapeListener() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }
  }

  onAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.container = event.element;
        this.wrapper = this.container?.parentElement;
        this.appendContainer();
        this.moveOnTop();
        this.bindGlobalListeners();
        this.container?.setAttribute(this.id, '');

        if (this.modal) {
          this.enableModality();
        }

        if (!this.modal && this.blockScroll) {
          this._addStyleClass(this._document.body, 'spaui-overflow-hidden');
        }

        if (this.focusOnShow) {
          this.focus();
        }
        break;

      case 'void':
        if (this.wrapper && this.modal) {
          this._addStyleClass(this.wrapper, 'spaui-component-overlay-leave');
        }
        break;
    }
  }

  onAnimationEnd(event: AnimationEvent) {
    switch (event.toState) {
      case 'void':
        this.onContainerDestroy();
        this._dialogRef?.destroy();
        this.hideChange.emit();
        this._cdr.markForCheck();
        break;
      case 'visible':
        this.showChange.emit();
        break;
    }
  }

  onContainerDestroy() {
    this.unbindGlobalListeners();
    this.dragging = false;
    this.maskVisible = false;

    if (this.maximized) {
      this._removeStyleClass(this._document.body, 'spaui-overflow-hidden');
      this.maximized = false;
    }

    if (this.modal) {
      this.disableModality();
    }

    if (this.blockScroll) {
      this._removeStyleClass(this._document.body, 'spaui-overflow-hidden');
    }

    if (this.container && this.autoZIndex) {
      this._zIndexManager.clearZIndex(this.container);
    }

    this.container = null;
    this.wrapper = null;
    this._style = this.originalStyle ? { ...this.originalStyle } : {};
  }

  unbindMaskClickListener() {
    if (this.maskClickListener) {
      this.maskClickListener();
      this.maskClickListener = null;
    }
  }

  closeDialogRef() {
    this._dialogRef?.close({ closed: true });
  }

  private _setupComponent(config: DialogConfig): void {
    Object.keys(config || {}).forEach((key) => {
      (this as any)[key] = config[key as DialogConfigKey];
    });
  }

  private _addStyleClass(element: HTMLElement, className: string): void {
    this._renderer.addClass(element, className);
  }

  private _removeStyleClass(element: HTMLElement, className: string): void {
    this._renderer.removeClass(element, className);
  }

  private _hasClass(
    element: Nullable<HTMLElement>,
    className: string
  ): Nullable<boolean> {
    return element?.classList?.contains(className);
  }
}
