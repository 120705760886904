import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { DialogRef } from '../models/dialog-ref.model';
import { DOCUMENT } from '@angular/common';
import { DialogConfig } from '../models/dialog-config.model';
import { DialogInjector } from '../models/dialog-injector.model';
import { DialogComponent } from '../components/dialog/dialog.component';

// import { DynamicDialogComponent } from '../components/dynamic-dialog/dynamic-dialog.component'

/**
 * Dynamic Dialog component methods.
 * @group Service
 */
@Injectable({ providedIn: 'root' })
export class DialogService {
  dialogComponentRefMap: Map<DialogRef, ComponentRef<DialogComponent>> =
    new Map();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {}
  /**
   * Displays the dialog using the dynamic dialog object options.
   * @param {*} componentType - Dynamic component for content template.
   * @param {DialogConfig} config - DynamicDialog object.
   * @returns {DialogRef} DynamicDialog instance.
   */
  open(componentType: Type<any>, config: DialogConfig): DialogRef {
    const dialogRef = this._appendDialogComponentToBody(config);

    this.dialogComponentRefMap.get(dialogRef)!.instance.contentComponentType =
      componentType;
    this.dialogComponentRefMap.get(dialogRef)!.instance.visible = true;
    return dialogRef;
  }

  private _appendDialogComponentToBody(config: DialogConfig) {
    const map = new WeakMap();
    map.set(DialogConfig, config);

    const dialogRef = new DialogRef();
    map.set(DialogRef, dialogRef);

    const sub = dialogRef.onClose.subscribe(() => {
      this.dialogComponentRefMap.get(dialogRef)?.instance?.close();
    });

    const destroySub = dialogRef.onDestroy.subscribe(() => {
      this._removeDialogComponentFromBody(dialogRef);
      destroySub.unsubscribe();
      sub.unsubscribe();
    });

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
    const componentRef = componentFactory.create(
      new DialogInjector(config.injector || this.injector, map)
    );

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    const cdkOverlayContainer = this.document.querySelector(
      '.cdk-overlay-container'
    );
    if (cdkOverlayContainer) {
      this.document.body.insertBefore(domElem, cdkOverlayContainer);
    } else {
      this.document.body.appendChild(domElem);
    }

    this.dialogComponentRefMap.set(dialogRef, componentRef);

    return dialogRef;
  }

  private _removeDialogComponentFromBody(dialogRef: DialogRef) {
    if (!dialogRef || !this.dialogComponentRefMap.has(dialogRef)) {
      return;
    }

    const dialogComponentRef = this.dialogComponentRefMap.get(dialogRef);
    if (dialogComponentRef) {
      this.appRef.detachView(dialogComponentRef.hostView);
      dialogComponentRef.destroy();
    }
    this.dialogComponentRefMap.delete(dialogRef);
  }
}
